import React, { useEffect, useState } from "react";
import sendEmail from "../Utils/apis/SendEmail";
import IEmailData from "../Utils/interfaces/Interfaces";
import WaitSpinner from "../Components/WaitSpinner";

const ContactView: React.FC = () => {

    const email: IEmailData = {
        Name: "",
        Email: "",
        Telephone: "",
        Subject: "",
        Message: ""
    }
    const [emailData, setEmailData] = useState(email);
    const [isLoader, setIsLoader] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState({
        isFailed: false,
        errorMsg: ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmailData({
            ...emailData,
            [e.target.name]: e.target.value
        });
    }

    const handleButtonClick = async () => {
        var validation = validateEmailForm(emailData);
        if (validation.isValid) {
            setIsLoader(true)
            setIsError({ isFailed: false, errorMsg: "" })
            const response: any = await sendEmail(emailData);
            setIsLoader(false)
            if (response.isEmailSent) {
                setIsSuccess(response.isEmailSent);
                setEmailData(email);
            } else {
                setIsError({
                    isFailed: true,
                    errorMsg: response.errorMessage
                })
            }

            setTimeout(() => {
                setIsSuccess(false);
            }, 10000);
        } else {
            setIsError({
                isFailed: true,
                errorMsg: validation.errorMessage
            })
        }
    };

    return (
        <section id="contact" className="contact section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Contact</h2>
                <div><span className="description-title">Get in Touch</span></div>
            </div>

            <div className="container" data-aos="fade" data-aos-delay="100">

                <div className="row gy-4">

                    <div className="col-lg-4">
                        <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                            <i className="bi bi-geo-alt flex-shrink-0" />
                            <div>
                                <h3>Address</h3>
                                <p>Pune</p>
                            </div>
                        </div>

                        <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                            <i className="bi bi-telephone flex-shrink-0" />
                            <div>
                                <h3>Call Us</h3>
                                <p><a href="tel:+918149347021">+91 8149 347 021</a></p>
                            </div>
                        </div>

                        <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                            <i className="bi bi-envelope flex-shrink-0" />
                            <div>
                                <h3>Email Us</h3>
                                <p>
                                    <a href="mailto:admin@sainfolines.com">admin@sainfolines.com</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <form className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                            <div className="row gy-4">

                                <div className="col-md-4">
                                    <label htmlFor="txtName">Full Name</label>
                                    <input type="text" id="txtName" name="Name" value={emailData.Name} onChange={handleChange} className="form-control" placeholder="Full Name" maxLength={100} />
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="txtEmail">Email</label>
                                    <input type="email" id="txtEmail" value={emailData.Email} onChange={handleChange} className="form-control" name="Email" placeholder="Email" />
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="txtTelephone">Telephone</label>
                                    <input type="text" id="txtTelephone" value={emailData.Telephone} onChange={handleChange} className="form-control" name="Telephone" maxLength={10} placeholder="Telephone" />
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="txtSubject">Subject</label>
                                    <input type="text" id="txtSubject" name="Subject" value={emailData.Subject} onChange={handleChange} className="form-control" placeholder="Subject" />
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="txtMessage">Message</label>
                                    <textarea name="Message" id="txtMessage" value={emailData.Message} onChange={handleChange} className="form-control" rows={6} placeholder="Message" />
                                </div>

                                <div className="col-md-12 text-center">
                                    {isLoader && <WaitSpinner />}
                                    {isSuccess && <span className="text-success">Email Send Successfully...</span>}
                                    {isError.isFailed && <span className="text-danger">Error: {isError.errorMsg}</span>}
                                </div>

                                <div className="col-md-12 text-center">
                                    <button type="button" className="btn" style={{ backgroundColor: "#1acc8d", color: "white" }} onClick={handleButtonClick} >Send Message</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>

            </div>

        </section>
    );
}

export default ContactView;


export const validateEmailForm = (emailData: IEmailData): IValidateEmail => {

    let validations: IValidateEmail = {
        isValid: true, errorMessage: ""
    };

    if (emailData.Name == "" || emailData.Name == undefined || emailData.Name == null) {
        return validations = {
            isValid: false, errorMessage: "Name id is required."
        }
    }

    if (emailData.Email == "" || emailData.Email == undefined || emailData.Email == null) {
        return validations = {
            isValid: false, errorMessage: "Email id is required."
        }
    } else if (emailData.Email) {
        const emailRegex: RegExp = /^\S+@\S+\.\S+$/;
        if (!emailRegex.test(emailData.Email)) {
            return validations = {
                isValid: false, errorMessage: "Enter valid email id."
            }
        }
    }

    if (emailData.Telephone == "" || emailData.Telephone == undefined || emailData.Telephone == null) {
        return validations = {
            isValid: false, errorMessage: "Telephone id is required."
        }
    } else if (emailData.Telephone != "") {
        // const validNumber = /^(0|[1-9]\d*)$/
        const validNumber = /^\d{10}$/
        if (!validNumber.test(emailData.Telephone)) {
            return validations = {
                isValid: false, errorMessage: "Telephone must be 10 digit number withiut space."
            }
        }
    }

    if (emailData.Subject == "" || emailData.Subject == undefined || emailData.Subject == null) {
        return validations = {
            isValid: false, errorMessage: "Subject id is required."
        }
    }

    if (emailData.Message == "" || emailData.Message == undefined || emailData.Message == null) {
        return validations = {
            isValid: false, errorMessage: "Message id is required."
        }
    }

    return validations;

}

interface IValidateEmail {
    isValid: boolean;
    errorMessage: string;
}