const AboutView: React.FC = () => {
    return (
        <section id="about" className="about section">

            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row align-items-xl-center gy-5">

                    <div className="col-xl-5 content">
                        <h3>About Us</h3>
                        <h2>About Company</h2>
                        <p>We are operating since 2017 and now we are a pioneer in what we do i.e. Software Consulting & Development.
                            We have a high list of satisfied clientele who trust us not for what we are but what we do even after post sales 
                        </p>
                        {/* <a href="#" className="read-more"><span>Read More</span><i className="bi bi-arrow-right" /></a> */}
                    </div>

                    <div className="col-xl-7">
                        <div className="row gy-4 icon-boxes">

                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon-box">
                                    <i className="bi bi-graph-up-arrow" />
                                    <h3>Support Services</h3>
                                    <p>
                                        Technology Support Services offering can ensure the optimal operation of your organization and entire Information Technology infrastructure and technical environment.
                                    </p>
                                    <p>
                                        Our comprehensive technical support services and solutions help to protect your often sizable investments in technology and ensure the continual operation and optimization of your organization.
                                    </p>
                                    <p>
                                        When we support your IT we give you a team of experts who are responsible day-to-day, for delivering IT service excellence to you.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon-box">
                                    <i className="bi bi-emoji-sunglasses" />
                                    <h3>Software Services</h3>
                                    <p>Our Software Services have broad architectural knowledge, deep technical skills and best practices expertise. We have services to suit all size of organisation, from corporate to SME.</p>
                                    <ul>
                                        <li>Academy Software</li>
                                        <li>ERP Application</li>
                                        <li>Blog Enabled Dynamic Website</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon-box">
                                    <i className="bi bi-clouds" />
                                    <h3>Our capabilities</h3>
                                    <p>
                                        <ul>
                                            <li>IT Strategic Planning</li>
                                            <li>Business Architecture</li>
                                            <li>Application Managementli</li>
                                            <li>IT Sourcing and Procurement</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box">
                                    <i className="bi bi-check2-all" />
                                    <h3>Technology Advisor</h3>
                                    <p>Our goal is to become your long-term technology advisor. We draw on global experience across industries and technologies to help solve companies’ most complex IT challenges.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default AboutView;